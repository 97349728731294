import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de fins a 7 cm de diàmetre, convex i deprimit al centre de color marró amb diferents tons, que presenta taques com de rovell. El marge és acanalat i presenta petits bonys. Les làmines blanquinoses són atapeïdes, bifurcades prop del peu i trencadisses. El peu és quasi cilíndric, amb el temps esponjós i finalment buit. Es reconeix ja que al tacte la carn pren una coloració rogenca. Les espores són de color crema pàl·lid en massa, ovoïdals o el·líptiques amb berrugues, de 6,5-8 x 5-6,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      